<template>
  <main class="main page">
    <section class="section">
      <h1 class="section__heading">Reset Password</h1>
      <form class="form" @submit.prevent="resetPassword">
        <div class="form__item form__item--text">
          <input
            v-model="password"
            :type="this.showPassword ? 'text' : 'password'"
            placeholder="New Password"
            :disabled="!validCode"
          >
        </div>
        <div class="form__item form__item--text">
          <input
            v-model="passwordAgain"
            :type="this.showPassword ? 'text' : 'password'"
            placeholder="Confirm New Password"
            :disabled="!validCode"
          >
        </div>
        <div class="form__item form__item--checkbox">
          <input
            type="checkbox"
            value="showPassword"
            name="show-password"
            class="show-password"
            v-model="showPassword"
          > Show Passwords
        </div>
        <button class="block primary">Reset Password</button>
        <ValidationWarning :valid="valid" :text="error" />
      </form>
    </section>
  </main>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth'
export default {
  data () {
    return {
      validCode: false,
      password: null,
      passwordAgain: null,
      valid: true,
      error: null,
      showPassword: false
    }
  },
  computed: {
    ...mapState(['firebaseAuth'])
  },
  async mounted () {
    await this.validateOobCode()
  },
  methods: {
    ...mapMutations(['setGlobalNotification']),
    async validateOobCode () {
      const resetCode = this.$route.query.oobCode
      try {
        await verifyPasswordResetCode(this.firebaseAuth, resetCode)
        this.validCode = true
      } catch (err) {
        this.error = err.message
        this.valid = false
        this.validCode = false
      }
    },
    validate () {
      this.error = null
      if (this.password !== this.passwordAgain) {
        this.error = 'Passwords do not match.'
        return false
      }
      return true
    },
    async resetPassword () {
      this.valid = this.validate()
      if (this.valid) {
        try {
          await confirmPasswordReset(this.firebaseAuth, this.$route.query.oobCode, this.password)
          this.setGlobalNotification('Password Reset')
          this.$router.push('/?signUp=true')
        } catch (err) {
          if (err.code === 'auth/weak-password') {
            this.error = 'Your new password should be at least 6 characters long.'
          } else {
            this.error = err.message.replace('Firebase: ', '')
          }
          this.valid = false
        }
      }
    }
  }
}
</script>
